import { REMOVE_PATIENT, SET_PATIENT } from './types'

const patient = (state = {
  fetching: false,
  data: null
}, action) => {
  switch (action.type) {
    case SET_PATIENT:
      return Object.assign({}, state, {
        data: action.payload
      })
    case REMOVE_PATIENT:
      return {
        fetching: false,
        data: undefined
      }
    default:
      return state
  }
}

export default patient
