import { SET_DOCTOR, REMOVE_DOCTOR, ADD_FAVORITE, EDIT_FAVORITE, REMOVE_FAVORITE } from './types'

export function setDoctor (doctor) {
  return {
    type: SET_DOCTOR,
    payload: doctor
  }
}

export function removeDoctor () {
  return {
    type: REMOVE_DOCTOR
  }
}

export function addFavorite (favorite) {
  return {
    type: ADD_FAVORITE,
    payload: favorite
  }
}

export function editFavorite (favorite) {
  return {
    type: EDIT_FAVORITE,
    payload: favorite
  }
}

export function removeFavorite (id) {
  return {
    type: REMOVE_FAVORITE,
    payload: id
  }
}
