import React from 'react'
import Basket from './Basket'
import { bindActionCreators } from 'redux'

import {basketActions} from '../../../../redux/basket'
import { connect } from 'react-redux'
import testMedicines from '../../../../test/testMedicines'
import Medicine from '../../../../models/Medicine'
import { withRouter } from 'react-router-dom'

class BasketContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {modalIsOpen: false}
  }
  render () {
    return <Basket
      handleSubmitClick={() => this.props.handleSubmitClick(this.props.items)}
      mobileBasketVisibilityToggle={() => this.setState({modalIsOpen: !this.state.modalIsOpen})}
      mobileBasketVisibility={this.state.modalIsOpen}
      items={this.props.items} />
  }
}

function mapStateToProps ({basket}) {
  const items = []
  basket.items.forEach((id, index, arr) => {
    const item = testMedicines.find(item => item.id === id)
    const {id: itemId, commercialName, activeComponent, dose, units, type, administrationType, price} = item
    const quantity = arr.filter(i => i === id).length
    const dosage = basket.dosages[id] || ''
    const renewable = basket.renewables[id] || 0
    const object = {
      id: id,
      renewable,
      dosage,
      entry: new Medicine(itemId, commercialName, activeComponent, dose, units, type, administrationType, price),
      quantity: quantity
    }
    if (!items.find(item => item.id === id)) {
      items.push(object)
    }
  })
  return {
    items
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(basketActions, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasketContainer))
