import Patient from '../models/Patient'
//
// const testPatients = {
//   id: 1,
//   name: 'Jorge Sousa',
//   birth: '1994-11-11',
//   healthcareNumber: '356047551',
//   gender: 'MALE'
// }
const testPatient = new Patient(1, 'Jorge Sousa', '1994-11-11', '356047551', 'MALE')

export default testPatient
