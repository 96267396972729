import React from 'react'
import FavoritesView from './FavoritesView'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { doctorActions } from '../../../redux/doctor'
import { basketActions } from '../../../redux/basket'
import Favorite from '../../../models/Favorite'

class FavoritesViewContainer extends React.Component {
  favoriteAddHandler (favorite) {
    favorite.medicines.forEach(medicine => {
      this.props.addItem(medicine.id)
      this.props.changeDosage(medicine.id, medicine.dosage)
      this.props.changeRenewable(medicine.id, medicine.renewable)
    })
  }

  render () {
    return <FavoritesView addFavoriteToReceipt={this.favoriteAddHandler.bind(this)} favorites={this.props.favorites} />
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({...doctorActions, ...basketActions}, dispatch)
}

const mapStateToProps = ({doctor}) => {
  const structured = []
  doctor.favorites.forEach(fav => structured.push(new Favorite(fav.id, fav.title, fav.items)))
  return {
    favorites: structured
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesViewContainer)
