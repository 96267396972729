import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

export const SEARCH = {
  ALL: 'all',
  INN: 'inn',
  COMMERCIAL: 'commercial',
  MEDICAL_DEVICES: 'medicalDevices',
  MANIPULATED: 'manipulated',
  DIETARY: 'dietary',
  OTHER: 'other',
  FAVOURITES: 'favourites',
  getDesignationForType: (type = '') => {
    switch (type) {
      case SEARCH.ALL:
        return 'Todos'
      case SEARCH.INN:
        return 'DCI'
      case SEARCH.COMMERCIAL:
        return 'Marca Comercial'
      case SEARCH.MEDICAL_DEVICES:
        return 'Dispositivos Médicos'
      case SEARCH.MANIPULATED:
        return 'Manipulados'
      case SEARCH.DIETARY:
        return 'Dietéticos'
      case SEARCH.OTHER:
        return 'Outros'
      case SEARCH.FAVOURITES:
        return 'Favoritos'
      default:
        return 'Todos'
    }
  }
}
const SearchForm = ({searchType, searchTypes, onTypeChange, onSearchChange, searchText}) => {
  return <form className='form-horizontal no-side-padding'>
    <div className='input-group no-side-padding'>
      <span className='input-group-btn'>
        <button
          className='btn btn-default dropdown-toggle'
          type='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'>
          {SEARCH.getDesignationForType(searchType)}
          <span className='caret' />
        </button>
        <ul className='dropdown-menu'>
          {
            searchTypes.map((type, index) => {
              return <li
                key={index}
                className={searchType === type ? 'active' : null}
                onClick={() => onTypeChange(type)}>
                {SEARCH.getDesignationForType(type)}
              </li>
            })
          }
        </ul>
      </span>
      <input
        onChange={(e) => onSearchChange(e.target.value, searchType)}
        type='text'
        value={searchText}
        className='form-control' placeholder='Nome Comercial, DCI...' />
      <span className='input-group-btn'>
        <button className='btn btn-default' type='button'><span className='glyphicon glyphicon-search' /></button>
      </span>
    </div>
  </form>
}

SearchForm.propTypes = {
  searchType: PropTypes.string.isRequired,
  searchTypes: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired
}

export default SearchForm
