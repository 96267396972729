const testMedicines = [
  {
    id: '813f6dd0-a2bc-46d0-98f2-682d4710b97d',
    commercialName: 'Omeprazol',
    activeComponent: 'Omeprazol',
    dose: '20mg',
    units: '60',
    type: 'pill',
    administrationType: 'oral',
    price: ''
  }, {
    id: 'becae097-f6e7-408d-be88-5c60fec35fdc',
    commercialName: 'Ácido Acetilsalicílico ',
    activeComponent: 'Ácido Acetilsalicílico ',
    dose: '100mg',
    units: '30',
    type: 'pill',
    administrationType: 'oral',
    price: ''
  }, {
    id: '1cf880ca-16eb-4d55-86b9-b5807ba800f6',
    commercialName: 'Lasix',
    activeComponent: 'Lasix',
    dose: '40mg',
    units: '60',
    type: 'pill',
    administrationType: 'oral',
    price: ''
  }, {
    id: '99cb32a3-4c2d-48f5-9748-1db694f91204',
    commercialName: 'Brometo de Ipratrópio PA',
    activeComponent: 'Brometo de Ipratrópio PA',
    dose: '20 ug/dose',
    units: '1',
    type: 'drops',
    administrationType: 'oral',
    price: ''
  }, {
    id: 'a9a3931d-f0f2-41c4-b456-2f008e5bde49',
    commercialName: 'Seretaide',
    activeComponent: 'Seretaide',
    dose: '25/125 ug/dose',
    units: '1',
    type: 'inhalator',
    administrationType: 'oral',
    price: ''
  }, {
    id: '038e2a76-9893-4a69-a3d4-fc096409b27f',
    commercialName: 'Hidroxizina',
    activeComponent: 'Hidroxizina',
    dose: '25mg',
    units: '60',
    type: 'pill',
    administrationType: 'oral',
    price: ''
  }, {
    id: '2b5fe03b-1f0a-49e0-a331-765cff425204',
    commercialName: 'Desloratadina',
    activeComponent: 'Desloratadina',
    dose: '5mg',
    units: '20',
    type: 'pill',
    administrationType: 'oral',
    price: ''
  }
]

export default testMedicines
