import React from 'react'

import { searchActions } from '../../../../../redux/search'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SearchForm from './SearchForm'

const allSearchTypes = [
  'all',
  'inn',
  'commercial',
  'medicalDevices',
  'manipulated',
  'dietary',
  'other',
  'favourites'
]

class SearchFormContainer extends React.Component {
  render () {
    const {onChange, currentState, searchType, onTypeChange} = this.props
    return <SearchForm
      searchTypes={allSearchTypes}
      searchText={currentState}
      searchType={searchType}
      onSearchChange={onChange}
      onTypeChange={onTypeChange} />
  }
}

function mapStateToProps ({search}) {
  return {
    currentState: search.current || '',
    searchType: search.type
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(searchActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormContainer)
