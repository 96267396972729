import React from 'react'
import PropTypes from 'prop-types'
import FullInfoMedicine from './FullInfoMedicine'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { basketActions } from '../../../../redux/basket'

class FullInfoMedicineContainer extends React.Component {
  render () {
    const {dosages, renewables, medicine, ...theRest} = this.props
    const observationsValue = dosages[this.props.medicine.id] || ''
    const renewableValue = renewables[this.props.medicine.id] || 0
    return <FullInfoMedicine
      {...theRest}
      observations={observationsValue}
      renewable={renewableValue}
      medicine={medicine} />
  }
}

FullInfoMedicineContainer.propTypes = {
  dosages: PropTypes.object.isRequired,
  renewables: PropTypes.object.isRequired,
  medicine: PropTypes.object.isRequired,
  observationsChange: PropTypes.func.isRequired,
  renewableChange: PropTypes.func.isRequired,
  handlePlusClick: PropTypes.func.isRequired,
  handleMinusClick: PropTypes.func.isRequired
}

function mapStateToProps ({basket}) {
  return {
    dosages: basket.dosages,
    renewables: basket.renewables
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(basketActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FullInfoMedicineContainer)
