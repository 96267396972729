import React from 'react'
import SectionTitle from '../../../common/SectionTitle/SectionTitle'
import SearchFormContainer from '../../Main/Search/SearchForm/SearchFormContainer'
import List from '../../Main/List/List'
import FullInfoMedicineContainer from '../../MedicationViews/FullInfoMedicine/FullInfoMedicineContainer'
import BasketContainer from '../../Main/Basket/BasketContainer'

const FavoriteAddition = ({ items, observationsChange, renewableChange, handlePlusClick, handleMinusClick, basketHasItems, handleSubmitClick }) => {
  return <div className='container-fluid no-side-padding-for-container'>
    <SectionTitle title={'Adição de Favoritos'} size={'h3'} icon={<span className='far fa-star' />} />
    <div className='row-fluid'>
      <div className={`col-md-8`}>
        <SearchFormContainer />
        <hr />
        <div className='col-xs-12'>
          {items.length > 0
            ? <List>
              {
                items.map((item, index) => {
                  return <FullInfoMedicineContainer
                    observationsChange={(observations) => observationsChange(item.id, observations)}
                    renewableChange={(number) => renewableChange(item.id, number)}
                    handlePlusClick={(id) => handlePlusClick(item.id)}
                    handleMinusClick={(id) => handleMinusClick(item.id)}
                    key={index}
                    medicine={item} />
                })
              }
            </List>
            : null
            // : <FavoriteRecipes handleMedicationAddition={() => {}} />
          }
        </div>
      </div>
      <BasketContainer handleSubmitClick={handleSubmitClick} />
    </div>
  </div>
}

export default FavoriteAddition
