import React from 'react'
import ShortInfoMedicine from './ShortInfoMedicine'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { basketActions } from '../../../../redux/basket'

class ShortInfoMedicineContainer extends React.Component {
  render () {
    return <ShortInfoMedicine
      addItem={this.props.addItem}
      removeAllOfType={this.props.removeAllOfType}
      removeItem={this.props.removeItem}
      medicine={this.props.medicine}
      dosage={this.props.dosage}
      quantity={this.props.quantity} />
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(basketActions, dispatch)
}

export default connect(null, mapDispatchToProps)(ShortInfoMedicineContainer)
