import React from 'react'
import FavoritesViewContainer from './FavoritesViewContainer'
import { Link } from 'react-router-dom'
import SectionTitle from '../../common/SectionTitle/SectionTitle'

const FavoritesViewer = () => {
  return <div className='container'>
    <SectionTitle
      icon={<span className='fa fa-star' />}
      title={'Os meus Favoritos'}
      size={'h4'}
      rightField={<Link to={'/addFavorite'}><button className='btn btn-success btn-sm'><span className='fas fa-plus' /> Adicionar</button></Link>} />
    <FavoritesViewContainer />
  </div>
}
export default FavoritesViewer
