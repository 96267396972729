import React, { Component, Fragment } from 'react'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import './App.css'
import { Link, Route } from 'react-router-dom'

import Switch from 'react-router-dom/es/Switch'

import logo from '../../../resources/images/logoKenetto-01.svg'
import { CustomLink } from '../../common/CustomLink/CustomLink'
import { Navigation } from '../../common/Navigation/Navigation'
import testPatient from '../../../test/testPatient'

import createStore from '@kenetto/kenetto-redux'
import reducers from '../../../redux'
import { basePathConfiguration } from '../../../config'
import PatientIdentification from '../Main/PatientIdentification/PatientIdentification'
import MainContainer from '../Main/MainContainer'
import FavoritesAdditionContainer from '../FavoritesView/FavoriteAddition/FavoritesAdditionContainer'
import ReceiptConfirmationView from '../ReceiptConfirmation/ReceiptConfirmationView'
import FavoritesViewer from '../FavoritesView/FavoritesViewer'

const reduxStore = createStore(basePathConfiguration, reducers)

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      navType: false
    }
  }

  navigationContent () {
    const result = []
    // TODO Put if patient is selected
    // const a = 1
    // if (a === 1) {
    //   result.push(<p key='number' className='navbar-text'><span style={{fontWeight: '300'}}>Nº SNS - </span>123456789</p>)
    //   result.push(<p key='name' className='navbar-text'><span style={{fontWeight: '300'}}>Nome - </span>Jorge Sousa (<span>23 Anos</span>)</p>)
    // }
    result.push(<CustomLink
      key={'home'}
      exact
      label={'Prescrição'}
      to={`/`} />)
    result.push(<CustomLink
      key={'favorites'}
      exact
      label={'Favoritos'}
      to={`/favorites`} />)
    result.push(<CustomLink
      key={'history'}
      exact
      label={'Histórico'}
      to={`/history`} />)
    return result
  }

  getDoctorGreeting () {
    return <Fragment>
      <div className='visible-sm'><span className='fa fa-user-md' /><span className='caret' /></div>
      <div className='hidden-sm'>Viva, Dr. Ricardo Pimenta-Araújo<span className='caret' /></div>
    </Fragment>
  }

  navigationRightContent () {
    // TODO Put if doctor is authenticated
    return <Fragment>
      <li key='patientSm' className='dropdown visible-sm visible-md'>
        <ul className='dropdown-menu'>
          <li><Link to='#'>Histórico de Prescrições</Link></li>
          <li><Link to='#'>Dados do Paciente</Link></li>
        </ul>
      </li>
      <li key='patient' className='dropdown hidden-sm hidden-md'>
        <ul className='dropdown-menu'>
          <li><Link to='#'>Histórico de Prescrições</Link></li>
          <li><Link to='#'>Dados do Paciente</Link></li>
        </ul>
      </li>
      <li key='settings' className='dropdown mobile-nav-border'>
        <a
          href='/'
          className='dropdown-toggle'
          data-toggle='dropdown'
          role='button'
          aria-haspopup='true'
          aria-expanded='false'>{this.getDoctorGreeting()}</a>
        <ul className='dropdown-menu'>
          <li><Link to='#'>doctorProfile</Link></li>
          <li><Link to='#'>logout</Link></li>
        </ul>
      </li>
    </Fragment>
  }

  navigationBrand () {
    return <Link className={'navbar-brand'} to={'/'}>
      <img className='navigation-logo' src={logo} alt='Logo' />
    </Link>
  }

  renderSwitch () {
    return <Switch>
      <Route exact path='/' render={props => (
        <MainContainer {...props} />
      )} />
      <Route path='/history' render={() => (
        <div>History</div>
      )} />
      <Route path='/favorites' render={() => (
        <FavoritesViewer />
      )} />
      <Route path='/confirmation' render={props => (
        <ReceiptConfirmationView {...props} />
      )} />
      <Route path='/addFavorite' render={() => (
        <FavoritesAdditionContainer />
      )} />
    </Switch>
  }

  render () {
    return (
      <Provider store={reduxStore.store}>
        <ConnectedRouter history={reduxStore.history}>
          <Fragment>
            <Navigation
              brand={this.navigationBrand()}
              children={this.navigationContent()}
              rightContent={this.navigationRightContent()} />
            <PatientIdentification patient={testPatient} hospital={'Hospital da Cruz Vermelha'} />
            <div className='container-fluid main-container no-side-padding-for-container' style={{marginTop: '20px'}}>
              {this.renderSwitch()}
            </div>
          </Fragment>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App
