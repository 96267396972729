import { ON_CHANGE, TYPE_CHANGE, RESET } from './types'

const search = (state = {
  current: '',
  type: 'all',
  results: []
}, action) => {
  switch (action.type) {
    case ON_CHANGE:
      return Object.assign({}, state, {
        current: action.payload.text,
        results: action.payload.results
      })
    case TYPE_CHANGE:
      return Object.assign({}, state, {
        type: action.payload
      })
    case RESET:
      return Object.assign({}, state, {
        results: [],
        current: null
      })
    default:
      return state
  }
}

export default search
