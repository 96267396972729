export default class Medicine {
  constructor (id, commercialName, activeComponent, dose, units, type, administrationType, price) {
    this._id = id
    this._commercialName = commercialName
    this._activeComponent = activeComponent
    this._dose = dose
    this._units = units
    this._type = type
    this._administrationType = administrationType
    this._price = price
  }

  get id () {
    return this._id
  }

  get commercialName () {
    return this._commercialName
  }

  get activeComponent () {
    return this._activeComponent
  }

  get dose () {
    return this._dose
  }

  get units () {
    return this._units
  }

  get type () {
    return this._type
  }

  get administrationType () {
    return this._administrationType
  }

  get price () {
    return this._price
  }
}
