import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import Patient from '../../../../models/Patient'
import moment from 'moment'

// TODO Decide if the patient number stays

const PatientIdentification = ({patient, hospital}) => {
  return <div className='row patient-identification'>
    <div className='col-lg-9 col-md-8 col-sm-12 col-xs-12 patient-id'>
      <div className='first'>
        <strong>
          {`${patient.gender === 'MALE' ? 'Mr.' : 'Mrs.'} ${patient.name}`}
        </strong>
      </div>
      <div className='hidden-xs'>{patient.getAge()} Anos</div>
      <div className='hidden-xs'>{moment(new Date(patient.birth)).format('L')}</div>
      {/* <div className='hidden-xs'>Nº - {`${patient.id}`}</div> */}
      <div>Nº SNS - {patient.externalNumber}</div>
    </div>
    <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 hospital hidden-xs'>
      <div>{hospital}</div>
    </div>
  </div>
}

PatientIdentification.propTypes = {
  hospital: PropTypes.string,
  patient: PropTypes.instanceOf(Patient).isRequired
}

export default PatientIdentification
