export default class Favorite {
  constructor (id, name, medicines) {
    this._id = id
    this._name = name
    this._medicines = medicines
  }

  get id () {
    return this._id
  }

  get name () {
    return this._name
  }

  get medicines () {
    return this._medicines
  }
}
