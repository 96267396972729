import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import Medicine from '../../../../models/Medicine'
import { Button } from '../../../common/Button/Button'
import { Panel } from '../../../common/Panel/Panel'

export default class ShortInfoMedicine extends React.Component {
  render () {
    const { medicine, quantity, dosage, addItem, removeItem, removeAllOfType } = this.props
    return <Panel
      panelType={'white'}
      heading={<div><span className='fa fa-pills icon-margin-right' />{medicine.commercialName} | {medicine.dose}</div>}
      defaultOpen={false}
      disableCollapse={false}
      rightHeader={<span className='badge'>x{quantity}</span>}>
      <div className='short-medicine-info'>
        <div className='line col-xs-12 no-side-padding'>
          Posologia <span className='content'>{dosage || 'Sem Posologia'}</span>
        </div>
        <div className='line col-xs-12 no-side-padding'>
          <Button text={'Remove All'} buttonType={'btn btn-danger btn-sm'} type={'button'} onClick={() => removeAllOfType(medicine.id)} />
          <div className='pull-right'>
            <Button text={<span className='fa fa-minus' />} buttonType={'btn btn-danger btn-sm'} type={'button'} onClick={() => removeItem(medicine.id)} />
            <Button text={<span className='fa fa-plus' />} buttonType={'btn btn-success btn-sm'} type={'button'} onClick={() => addItem(medicine.id)} />
          </div>
        </div>
      </div>
    </Panel>
  }
}

ShortInfoMedicine.propTypes = {
  medicine: PropTypes.instanceOf(Medicine).isRequired,
  dosage: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  removeAllOfType: PropTypes.func.isRequired

}
