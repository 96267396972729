const ADD_ITEM = 'basket//ADD_ITEM'
const ADD_ITEMS = 'basket//ADD_ITEMS'
const REMOVE_ITEM = 'basket//REMOVE_ITEM'
const REMOVE_ALL = 'basket//REMOVE_ALL'
const REMOVE_ALL_OF_TYPE = 'basket//REMOVE_ALL_OF_TYPE'
const ADD_DOSAGE = 'basket//ADD_DOSAGE'
const CHANGE_DOSAGE = 'basket//CHANGE_DOSAGE'
const CHANGE_RENEWABLE = 'basket//CHANGE_RENEWABLE'

export {
  ADD_ITEM,
  ADD_ITEMS,
  REMOVE_ITEM,
  REMOVE_ALL,
  REMOVE_ALL_OF_TYPE,
  ADD_DOSAGE,
  CHANGE_DOSAGE,
  CHANGE_RENEWABLE
}
