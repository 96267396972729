import React, { Fragment } from 'react'
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'
import InfoMedication from '../MedicationViews/InfoMedication/InfoMedication'
import { Button } from '../../common/Button/Button'

const FavoritesView = ({favorites, addFavoriteToReceipt}) => {
  return <Fragment>
    {favorites.length
      ? <Accordion>
        {favorites.map(fav => {
          return <AccordionItem key={fav.id}>
            <AccordionItemTitle>{fav.name}</AccordionItemTitle>
            <AccordionItemBody>
              <ul>
                {fav.medicines.map((item) => {
                  return <InfoMedication
                    key={item.id}
                    dosage={item.dosage}
                    dose={item.entry.dose}
                    price={item.entry.price}
                    commercialName={item.entry.commercialName}
                    activeComponent={item.entry.activeComponent}
                    type={item.entry.type}
                    units={item.entry.units} />
                })}
              </ul>
              <div className='clearfix'>
                <Button text={'Adicionar'} buttonType={'btn-success pull-right'} type={'button'} onClick={() => addFavoriteToReceipt(fav)} />
              </div>
            </AccordionItemBody>
          </AccordionItem>
        })}
      </Accordion>
      : null
    }
  </Fragment>
}

export default FavoritesView
