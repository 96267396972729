import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = (props) => {
  switch (props.size) {
    case 'h1':
      return <div className='page-header'>
        <h1><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h1>
      </div>
    case 'h2':
      return <div className='page-header'>
        <h2><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h2>
      </div>
    case 'h3':
      return <div className='page-header'>
        <h3><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h3>
      </div>
    case 'h4':
      return <div className='page-header'>
        <h4><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h4>
      </div>
    case 'h5':
      return <div className='page-header'>
        <h5><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h5>
      </div>
    default:
      return <div className='page-header'>
        <h1><span>{props.icon}</span>{props.title} <small>{props.subText}</small><span className='pull-right'>{props.rightField}</span></h1>
      </div>
  }
}

SectionTitle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).isRequired,
  subText: PropTypes.string,
  rightField: PropTypes.object
}

SectionTitle.defaultProps = {
  size: 'h1'
}

export default SectionTitle
