import React from 'react'
import ReceiptConfirmation from './ReceiptConfirmation'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import testMedicines from '../../../test/testMedicines'
import Medicine from '../../../models/Medicine'

import { basketActions } from '../../../redux/basket'

class ReceiptConfirmationContainer extends React.Component {
  handleDosageChange (id, dosage) {
    this.props.changeDosage(id, dosage)
  }

  render () {
    return <ReceiptConfirmation handleConfirmationClick={this.props.handleConfirmationClick} handleDosageChange={this.handleDosageChange.bind(this)} items={this.props.items} />
  }
}

const mapStateToProps = ({basket}) => {
  const items = []
  basket.items.forEach((id, index, arr) => {
    const item = testMedicines.find(item => item.id === id)
    const {id: itemId, commercialName, activeComponent, dose, units, type, administrationType, price} = item
    const quantity = arr.filter(i => i === id).length
    const dosage = basket.dosages[id] || ''
    const renewable = basket.renewables[id] || 0
    const object = {
      id: id,
      dosage,
      renewable,
      entry: new Medicine(itemId, commercialName, activeComponent, dose, units, type, administrationType, price),
      quantity: quantity
    }
    if (!items.find(item => item.id === id)) {
      items.push(object)
    }
  })
  return {
    items
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(basketActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptConfirmationContainer)
