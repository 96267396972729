import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Medicine from '../../../../models/Medicine'

import './style.css'

// _activeComponent: "Omeprazol"
// _administrationType: "oral"
// _commercialName: "Omeprazol"
// _dose: "20mg"
// _id: "813f6dd0-a2bc-46d0-98f2-682d4710b97d"
// _price: ""
// _type: "pill"
// _units: "60"

const Indicator = ({name, secondaryName, dose, type, units}) => {
  return <Fragment>
    <span className='main'>
      <span className='fa fa-pills' />{`${name}`}
    </span>
    <span className='secondary'>{dose}</span>
    <span className='secondary'>{secondaryName}</span>
    <span className='secondary'>{units}</span>
  </Fragment>
}

const StaticMedicine = ({medicine, renewable, dosage, quantity, changeDosage}) => {
  return <div className={`row static-medicine ${!dosage ? 'no-info' : ''}`}>
    <div>
      <div className='col-xs-9 static-medication-info-line no-side-padding'>
        <Indicator
          type={medicine.type}
          name={medicine.activeComponent}
          secondaryName={medicine.commercialName}
          dose={medicine.dose}
          units={medicine.units} />
      </div>
      <div className='col-xs-3 no-side-padding'>
        <div className='pull-right'>
          <span className='badge'>x{quantity}</span>
          <span className='badge'><i className='fas fa-recycle' /> x{renewable}</span>
        </div>
      </div>

    </div>
    <div className={`col-xs-12 no-side-padding ${!dosage ? 'no-info' : ''}`}>
      <div className='static-medication-info-line'>
        <span className='main'>Posologia</span>
        <div className={`form-group ${!dosage ? 'has-warning has-feedback' : ''}`}>
          <input
            type='text'
            className='form-control'
            placeholder='Posologia'
            value={dosage}
            onChange={e => changeDosage(e.target.value)}
            aria-describedby='inputWarning2Status' />
          {!dosage
            ? <Fragment>
              <span className='glyphicon glyphicon-warning-sign form-control-feedback' aria-hidden='true' />
              <span id='inputWarning2Status' className='sr-only'>(warning)</span>
            </Fragment>
            : null
          }
        </div>
      </div>
    </div>
  </div>
}

StaticMedicine.propTypes = {
  medicine: PropTypes.instanceOf(Medicine),
  renewable: PropTypes.number.isRequired,
  dosage: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  changeDosage: PropTypes.func.isRequired
}

export default StaticMedicine
