import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import List from '../List/List'

import './style.css'
import { Button } from '../../../common/Button/Button'
import { Panel } from '../../../common/Panel/Panel'
import Medicine from '../../../../models/Medicine'
import ShortInfoMedicineContainer from '../../MedicationViews/ShortInfoMedicine/ShortInfoMedicineContainer'
import { ReactModal } from '../../../common/ReactModal/ReactModal'

function renderElements (items) {
  if (items.length > 0) {
    return items.map(item => {
      return <ShortInfoMedicineContainer
        key={item.id}
        medicine={item.entry}
        dosage={item.dosage}
        quantity={item.quantity} />
    })
  } else {
    return <div>Não existem Itens</div>
  }
}

function MobileBasketButton ({handleClick}) {
  return <div className='float-right visible-sm visible-xs' style={{position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000}}>
    <Button text={<span className='glyphicon glyphicon-list-alt' />} buttonType={'btn btn-primary'} type={'button'} onClick={handleClick} />
    <div className='notification-bubble' />
  </div>
}

const BasketContent = ({items, handleSubmitClick}) => {
  return <Fragment>
    <List>
      {renderElements(items)}
    </List>
    {Object.keys(items).length > 0
      ? <Button
        text={'Prescrever'}
        buttonType={'btn btn-primary btn-block'}
        type={'button'}
        onClick={handleSubmitClick} />
      : null
    }
  </Fragment>
}

const PanelBasket = ({items, handleSubmitClick}) => {
  return <div className='col-xs-12 col-md-4 pull-right hidden-sm hidden-xs'>
    <Panel
      disableCollapse={false}
      heading='Selecção Atual'
      rightHeader={
        <div className='panel-title pull-right'>
          <span className='badge'>
            {items.length}
          </span>
        </div>}>
      <BasketContent items={items} handleSubmitClick={handleSubmitClick} />
    </Panel>
  </div>
}

const ModalBasket = ({items, handleSubmitClick, mobileBasketVisibilityToggle, mobileBasketVisibility}) => {
  return <Fragment>
    <MobileBasketButton handleClick={() => mobileBasketVisibilityToggle()} />
    <ReactModal fullWidth name={'Selecção Atual'} toggleModal={mobileBasketVisibilityToggle} modalIsOpen={mobileBasketVisibility}>
      <BasketContent items={items} handleSubmitClick={handleSubmitClick} />
    </ReactModal>
  </Fragment>
}

const Basket = (props) => {
  return <Fragment>
    <PanelBasket {...props} />
    <ModalBasket {...props} />
  </Fragment>
}

Basket.propTypes = {
  handleSubmitClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    entry: PropTypes.instanceOf(Medicine),
    dosage: PropTypes.string,
    quantity: PropTypes.number.isRequired
  }))
}

export default Basket
