import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/general/App/App'
import createBrowserHistory from 'history/createBrowserHistory'
import '@fortawesome/fontawesome-free/css/all.css'
// import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

const history = createBrowserHistory({ basename: process.env.REACT_APP_ROUTER_BASE_PATH })

export default history

ReactDOM.render(<App />, document.getElementById('root'))
