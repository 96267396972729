import { ON_CHANGE, TYPE_CHANGE, RESET } from './types'

import testMedicines from '../../test/testMedicines'
import { escapeRegExp } from '../../resources/utilities'

function searchByName (text) {
  const result = []
  if (text === '') {
    return result
  }
  testMedicines.forEach(element => {
    text = escapeRegExp(text)
    const lowerCaseName = element.commercialName.toLowerCase()
    const lowerCaseSearchText = text.toLowerCase()
    if (lowerCaseName.match(lowerCaseSearchText)) {
      result.push(element)
    }
  })
  return result
}

function searchByInn (text) {
  const result = []
  testMedicines.forEach(element => {
    text = escapeRegExp(text)
    const lowerCaseInn = element.inn.toLowerCase()
    const lowerCaseSearchText = text.toLowerCase()
    if (lowerCaseInn.match(lowerCaseSearchText)) {
      result.push(element)
    }
  })
  return result
}

export function onChange (text, type) {
  return dispatch => {
    let results = []
    if (type === 'all') {
      results = searchByName(text)
    } else {
      results = searchByInn(text)
    }
    dispatch({
      type: ON_CHANGE,
      payload: {
        text,
        results
      }})
  }
}

export function onTypeChange (type) {
  return {
    type: TYPE_CHANGE,
    payload: type
  }
}

export function reset () {
  return {
    type: RESET
  }
}
