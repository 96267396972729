import React from 'react'
import StaticMedicine from '../MedicationViews/StaticMedicine/StaticMedicine'

const ReceiptConfirmation = ({items, handleDosageChange, handleConfirmationClick}) => {
  return <div>
    {
      items.map(item => {
        const {entry: medicine, dosage, renewable, id, quantity} = item
        return <StaticMedicine changeDosage={(value) => handleDosageChange(id, value)} quantity={quantity} key={id} dosage={dosage} renewable={renewable || 0} medicine={medicine} />
      })
    }
    <button className='btn btn-success btn-lg btn-block' onClick={handleConfirmationClick}>Confirmar</button>
  </div>
}

export default ReceiptConfirmation
