import React from 'react'
import Main from './Main'
import { connect } from 'react-redux'
import Medicine from '../../../models/Medicine'
import { basketActions } from '../../../redux/basket'
import { bindActionCreators } from 'redux'

class MainContainer extends React.Component {
  handlePlusClick (medicineId) {
    this.props.addItem(medicineId)
  }

  handleMinusClick (medicineId) {
    this.props.removeItem(medicineId)
  }
  handleDosageChange (medicineId, dosage) {
    this.props.changeDosage(medicineId, dosage)
  }

  handleRenewableChange (medicineId, times) {
    const {changeRenewable, basketItems} = this.props
    if (!basketItems.find(item => item === medicineId)) {
      this.props.addItem(medicineId)
    }
    changeRenewable(medicineId, times)
  }

  handleBasketSubmit () {
    console.log('here is where the magic happens')
    // TODO change *IF* to contain real confirmation
    const state = true
    if (state) {
      this.props.history.push('/confirmation', {isValid: true})
    }
  }
  render () {
    return <Main
      handleSubmitClick={this.handleBasketSubmit.bind(this)}
      observationsChange={this.handleDosageChange.bind(this)}
      renewableChange={this.handleRenewableChange.bind(this)}
      handlePlusClick={this.handlePlusClick.bind(this)}
      handleMinusClick={this.handleMinusClick.bind(this)}
      searchResults={this.props.searchResults}
      basketHasItems={this.props.basketHasItems} />
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(basketActions, dispatch)
}

function mapStateToProps ({basket, search}) {
  const structuredResults = []
  search.results.forEach(item => {
    const { id, commercialName, activeComponent, dose, units, type, administrationType, price } = item
    structuredResults.push(new Medicine(id, commercialName, activeComponent, dose, units, type, administrationType, price))
  })
  return {
    searchResults: structuredResults,
    basketItems: basket.items,
    basketHasItems: !!basket.items.length
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
