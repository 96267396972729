import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import { Button } from '../../../common/Button/Button'
import Medicine from '../../../../models/Medicine'

// {
//   name: 'Ben-u-ron',
//     dose: '500mg',
//   homogeneousGroup: 'Grupo',
//   inn: 'Paracetamol',
//   quantity: '24x',
//   packing: 'Comprimidos',
//   price: '2.34'
// }

const FullInfoMedicine = ({medicine, observations, renewable, observationsChange, renewableChange, handlePlusClick, handleMinusClick}) => {
  const { id, commercialName, activeComponent, dose, units, type } = medicine
  return <div className='row medication'>
    <div className='col-xs-12 col-sm-8'>
      <div className='medication-info-line'>
        <div className='first-line'><span className='fa fa-pills icon' />{`${activeComponent}, ${type}`}</div>
        <span className='secondary'>{commercialName}</span>
        <span className='secondary'>{dose}</span>
        <span className='secondary'>{units}</span>
      </div>
      <div className='medication-info-line'>
        <div className='form-group'>
          <input type='text' className='form-control' placeholder='Posologia' value={observations} onChange={e => { observationsChange(e.target.value) }} />
        </div>
      </div>
    </div>
    <div className='col-xs-12 col-sm-4 pull-right'>
      <span className='renewable-description'><i className='fas fa-recycle' /> Vias Renováveis:</span>
      <div className='pull-right'>
        <Button text={'1'} buttonType={`btn btn-${renewable === 1 ? 'primary' : 'default'} btn-sm`} type={'button'} onClick={() => renewableChange(1)} />
        <Button text={'2'} buttonType={`btn btn-${renewable === 2 ? 'primary' : 'default'} btn-sm`} type={'button'} onClick={() => renewableChange(2)} />
        <Button text={'3'} buttonType={`btn btn-${renewable === 3 ? 'primary' : 'default'} btn-sm`} type={'button'} onClick={() => renewableChange(3)} />
      </div>
    </div>
    <div className='col-xs-12 col-sm-4 pull-right'>
      <Button text={<span className='fa fa-minus' />} buttonType={'btn minus-button btn-sm btn-xs-block'} type={'button'} onClick={() => handleMinusClick(id)} />
      <Button text={<span className='fa fa-plus' />} buttonType={'btn plus-button btn-sm btn-xs-block'} type={'button'} onClick={() => handlePlusClick(id)} />
    </div>
  </div>
}

FullInfoMedicine.propTypes = {
  medicine: PropTypes.instanceOf(Medicine).isRequired,
  handlePlusClick: PropTypes.func.isRequired,
  handleMinusClick: PropTypes.func.isRequired
}

export default FullInfoMedicine
