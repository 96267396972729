import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import BasketContainer from './Basket/BasketContainer'
import SearchFormContainer from './Search/SearchForm/SearchFormContainer'
import List from './List/List'
import FullInfoMedicineContainer from '../MedicationViews/FullInfoMedicine/FullInfoMedicineContainer'
import FavoritesViewContainer from '../FavoritesView/FavoritesViewContainer'

const Main = ({searchResults, basketHasItems, observationsChange, renewableChange, handlePlusClick, handleMinusClick, handleSubmitClick}) => {
  return <div className='container-large no-side-padding-for-container'>
    <div className='row-fluid'>
      <div className={`col-xs-12 col-md-8`}>
        <SearchFormContainer />
        <hr />
        <div className='col-xs-12'>
          {searchResults.length > 0
            ? <List>
              {
                searchResults.map((item, index) => {
                  return <FullInfoMedicineContainer
                    observationsChange={(observations) => observationsChange(item.id, observations)}
                    renewableChange={(number) => renewableChange(item.id, number)}
                    handlePlusClick={(id) => handlePlusClick(item.id)}
                    handleMinusClick={(id) => handleMinusClick(item.id)}
                    key={index}
                    medicine={item} />
                })
              }
            </List>
            : <FavoritesViewContainer />
          }
        </div>
      </div>
      <BasketContainer handleSubmitClick={handleSubmitClick} />
    </div>
  </div>
}

Main.propTypes = {
  basketHasItems: PropTypes.bool.isRequired
}

export default Main
