import { ADD_ITEM, REMOVE_ITEM, REMOVE_ALL, REMOVE_ALL_OF_TYPE, CHANGE_DOSAGE, CHANGE_RENEWABLE } from './types'

const testItems = []

testItems.push('813f6dd0-a2bc-46d0-98f2-682d4710b97d')
testItems.push('becae097-f6e7-408d-be88-5c60fec35fdc')
testItems.push('1cf880ca-16eb-4d55-86b9-b5807ba800f6')
testItems.push('99cb32a3-4c2d-48f5-9748-1db694f91204')
testItems.push('a9a3931d-f0f2-41c4-b456-2f008e5bde49')
testItems.push('038e2a76-9893-4a69-a3d4-fc096409b27f')
testItems.push('2b5fe03b-1f0a-49e0-a331-765cff425204')

const basket = (state = {
  items: testItems,
  dosages: {
    '813f6dd0-a2bc-46d0-98f2-682d4710b97d': '2 vezes por dia',
    'becae097-f6e7-408d-be88-5c60fec35fdc': '2 vezes por dia',
    '1cf880ca-16eb-4d55-86b9-b5807ba800f6': '2 vezes por dia'
  },
  renewables: {}
}, action) => {
  switch (action.type) {
    case ADD_ITEM:
      const current = state.items
      let newArray = current.slice()
      newArray.splice(current.length, 0, action.payload)
      return Object.assign({}, state, {
        items: newArray
      })
    case REMOVE_ALL_OF_TYPE:
      return Object.assign({}, state, {
        items: state.items.filter(item => item !== action.payload)
      })
    case REMOVE_ITEM:
      const newArr = state.items
      const index = newArr.lastIndexOf(action.payload)
      if (index !== -1) {
        newArr.splice(index, 1)
      }
      return Object.assign({}, state, {
        items: newArr
      })
    case REMOVE_ALL:
      return Object.assign({}, state, {
        renewables: {},
        dosages: {},
        items: []
      })
    case CHANGE_DOSAGE:
      const newDosages = state.dosages
      newDosages[action.payload.medicineId] = action.payload.dosage
      return Object.assign({}, state, {
        dosages: newDosages
      })
    case CHANGE_RENEWABLE:
      const newRenewable = state.renewables
      newRenewable[action.payload.medicineId] = action.payload.times
      return Object.assign({}, state, {
        renewables: newRenewable
      })
    default:
      return state
  }
}

export default basket
