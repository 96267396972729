import moment from 'moment'

export default class Patient {
  constructor (id, name, birth, gender, group, healthcareNumber, externalNumber, diseases) {
    this._id = id
    this._name = name
    this._birth = birth
    this._externalNumber = externalNumber
    this._gender = gender
    this._group = group
    this._healthcareNumber = healthcareNumber
    this._diseases = diseases
  }

  get id () {
    return this._id
  }

  get name () {
    return this._name
  }

  get birth () {
    return this._birth
  }

  get gender () {
    return this._gender
  }

  get group () {
    return this._group
  }

  get healthcareNumber () {
    return this._healthcareNumber
  }

  get externalNumber () {
    return this._externalNumber
  }

  get diseases () {
    return this._diseases
  }

  getAge () {
    return moment().diff(this._birth, 'years')
  }
}
