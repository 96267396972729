import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Panel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bodyIsOpen: props.defaultOpen
    }
  }
  toggleBody (insideRightHeader) {
    if (!this.props.disableCollapse) {
      this.setState({
        bodyIsOpen: !this.state.bodyIsOpen
      })
    }
  }
  render () {
    return <div className={`panel ${this.props.panelType}`}>
      <div
        className='panel-heading'
        style={this.props.disableCollapse ? {cursor: 'auto'} : {cursor: 'pointer'}}>
        <div className='row'>
          <div className='col-xs-9 text-left' onClick={this.toggleBody.bind(this)}>{this.props.heading}</div>
          {this.props.rightHeader
            ? <div className='text-right'>{this.props.rightHeader}</div>
            : null
          }
        </div>
      </div>
      <div className={`panel-content ${this.state.bodyIsOpen ? 'collapse in' : 'collapse'}`} >
        <div className='panel-body' >
          {this.props.children}
        </div>
        {this.props.footer
          ? <div className='panel-footer'>{this.props.footer}</div>
          : null
        }
      </div>
    </div>
  }
}

Panel.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  rightHeader: PropTypes.object,
  panelType: PropTypes.string,
  footer: PropTypes.object,
  defaultOpen: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  disableCollapse: PropTypes.bool
}

Panel.defaultProps = {
  panelType: 'panel-default',
  defaultOpen: true,
  disableCollapse: true
}

export {Panel}
