import React from 'react'
import { Redirect } from 'react-router-dom'
import ReceiptConfirmationContainer from './ReceiptConfirmationContainer'

const ReceiptConfirmationView = ({location}) => {
  if (location.state && location.state.isValid) {
    return <div className='container'>
      <ReceiptConfirmationContainer handleConfirmationClick={() => {}} />
    </div>
  } else {
    return <Redirect to={'/'} />
  }
}

export default ReceiptConfirmationView
