import { SET_DOCTOR, REMOVE_DOCTOR, REMOVE_FAVORITE, EDIT_FAVORITE, ADD_FAVORITE } from './types'

const doctor = (state = {
  fetching: false,
  data: {},
  preferences: null,
  favorites: []
}, action) => {
  switch (action.type) {
    case SET_DOCTOR:
      return Object.assign({}, state, {
        data: action.payload.data,
        preferences: action.payload.preferences,
        favorites: action.payload.favorites
      })
    case REMOVE_DOCTOR:
      return {
        fetching: false,
        data: undefined
      }
    case ADD_FAVORITE:
      const current = state.favorites
      let newArray = current.slice()
      newArray.splice(current.length, 0, action.payload)
      return Object.assign({}, state, {
        favorites: newArray
      })
    case EDIT_FAVORITE:
      return state
    case REMOVE_FAVORITE:
      return state
    default:
      return state
  }
}

export default doctor
