import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const InfoMedication = ({commercialName, activeComponent, dose, price, type, units, dosage}) => {
  return <div className='row medication'>
    <div className='col-xs-12 col-sm-9'>
      <div className='medication-info-line'>
        <div className='first-line'><span className='fa fa-pills' />{`${activeComponent}, ${dose}`}</div>
        <span className='secondary'>{commercialName}</span>
        <span className='secondary'>{type}</span>
        <span className='secondary'>{units}</span>
      </div>
      <div className='medication-info-line'>
        <div className='form-group'>
          <input type='text' className='form-control' placeholder='Posologia' defaultValue={dosage} />
        </div>
      </div>
    </div>
  </div>
}

InfoMedication.propTypes = {
  commercialName: PropTypes.string.isRequired,
  activeComponent: PropTypes.string.isRequired,
  dose: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  dosage: PropTypes.string.isRequired
}

export default InfoMedication
