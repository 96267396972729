import { ADD_ITEM, REMOVE_ITEM, REMOVE_ALL, CHANGE_DOSAGE, CHANGE_RENEWABLE, REMOVE_ALL_OF_TYPE } from './types'

export function addItem (id) {
  return {
    type: ADD_ITEM,
    payload: id
  }
}

export function addItems (ids) {
  return dispatch => {
    ids.forEach(id => {
      dispatch({
        type: ADD_ITEM,
        payload: id
      })
    })
  }
}

export function removeItem (id) {
  return {
    type: REMOVE_ITEM,
    payload: id
  }
}

export function removeAllOfType (id) {
  return {
    type: REMOVE_ALL_OF_TYPE,
    payload: id
  }
}

export function removeAll () {
  return {
    type: REMOVE_ALL
  }
}

export function changeDosage (medicineId, dosage) {
  return {
    type: CHANGE_DOSAGE,
    payload: {
      medicineId,
      dosage
    }
  }
}

export function changeRenewable (medicineId, times) {
  return {
    type: CHANGE_RENEWABLE,
    payload: {
      medicineId,
      times
    }
  }
}
