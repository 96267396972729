/**
 * Created by valentin on 20/07/2017.
 */
import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Renderer } from '../Renderer/Renderer'
import './style.css'

class ReactModal extends React.Component {
  render () {
    const width = this.props.fullWidth ? 'full-width' : null
    return <div className='modal'>
      <Modal
        bodyOpenClassName={'modal-open'}
        overlayClassName='modal fade in'
        shouldReturnFocusAfterClose={false}
        className={`Modal__Bootstrap modal-dialog ${width}`}
        ref='Modal'
        id='Modal'
        style={{
          overlay: {
            display: 'block',
            paddingLeft: '0px'
          }
        }}
        ariaHideApp={false}
        closeTimeoutMS={150}
        isOpen={this.props.modalIsOpen}
        contentLabel={this.props.name}>
        <div className='modal-content'>
          <div className='modal-header'>
            {!this.props.disableClosing
              ? <button type='button' className='close' onClick={this.props.toggleModal}>
                <span>&times;</span>
                <span className='sr-only'>Close</span>
              </button>
              : null
            }
            {!this.refs.Modal ? null
              : <h4 className='modal-title'>{this.refs.Modal.props.contentLabel}</h4>
            }
          </div>
          <div className='modal-body'>
            {this.props.children}
          </div>
          <Renderer data={this.props.footer} noMessage>
            <div className='modal-footer'>
              {this.props.footer}
            </div>
          </Renderer>
        </div>
      </Modal>
    </div>
  }
}

ReactModal.propTypes = {
  name: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  footer: PropTypes.object,
  children: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  disableClosing: PropTypes.bool
}

ReactModal.defaultProps = {
  disableClosing: false,
  fullWidth: false
}

export { ReactModal }
