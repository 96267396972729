import React, { Fragment } from 'react'
import FavoriteAddition from './FavoriteAddition'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../redux/basket'
import { doctorActions } from '../../../../redux/doctor'
import { searchActions } from '../../../../redux/search'
import Medicine from '../../../../models/Medicine'
import { ReactModal } from '../../../common/ReactModal/ReactModal'
import ReceiptConfirmationContainer from '../../ReceiptConfirmation/ReceiptConfirmationContainer'

import v4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import testMedicines from '../../../../test/testMedicines'

class FavoritesAdditionContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      title: 'Favorito Teste',
      modalIsOpen: false
    }
  }

  componentWillUnmount () {
    this.props.reset()
  }

  componentDidMount () {
    this.props.removeAll()
  }
  handlePlusClick (medicineId) {
    this.props.addItem(medicineId)
  }

  handleMinusClick (medicineId) {
    this.props.removeItem(medicineId)
  }
  handleDosageChange (medicineId, dosage) {
    this.props.changeDosage(medicineId, dosage)
  }

  handleRenewableChange (medicineId, times) {
    const {changeRenewable, basketItems} = this.props
    if (!basketItems.find(item => item === medicineId)) {
      this.props.addItem(medicineId)
    }
    changeRenewable(medicineId, times)
  }
  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  handleBasketSubmit (i) {
    this.toggleModal()
  }

  addToFavorites () {
    const { title } = this.state
    const { items } = this.props
    this.props.addFavorite({id: v4(), title, items})
    this.props.removeAll()
    this.toggleModal()
    this.props.history.push('/favorites')
  }
  render () {
    return <Fragment>
      <FavoriteAddition
        handleSubmitClick={this.handleBasketSubmit.bind(this)}
        basketHasItems={this.props.basketHasItems}
        observationsChange={this.handleDosageChange.bind(this)}
        renewableChange={this.handleRenewableChange.bind(this)}
        handlePlusClick={this.handlePlusClick.bind(this)}
        handleMinusClick={this.handleMinusClick.bind(this)}
        items={this.props.searchResults} />
      <ReactModal name={'Confirmação'} toggleModal={this.toggleModal.bind(this)} modalIsOpen={this.state.modalIsOpen}>
        <Fragment>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Nome do Favorito'
              value={this.state.title}
              onChange={e => this.setState({title: e.target.value})} />
          </div>
          <ReceiptConfirmationContainer handleConfirmationClick={this.addToFavorites.bind(this)} />
        </Fragment>
      </ReactModal>
    </Fragment>
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...basketActions, ...doctorActions, ...searchActions }, dispatch)
}

const mapStateToProps = ({basket, search}) => {
  const structuredResults = []
  search.results.forEach(item => {
    const { id, commercialName, activeComponent, dose, units, type, administrationType, price } = item
    structuredResults.push(new Medicine(id, commercialName, activeComponent, dose, units, type, administrationType, price))
  })
  const items = []
  basket.items.forEach((id, index, arr) => {
    const item = testMedicines.find(item => item.id === id)
    const {id: itemId, commercialName, activeComponent, dose, units, type, administrationType, price} = item
    const quantity = arr.filter(i => i === id).length
    const dosage = basket.dosages[id] || ''
    const renewable = basket.renewables[id] || 0
    const object = {
      id: id,
      dosage,
      renewable,
      entry: new Medicine(itemId, commercialName, activeComponent, dose, units, type, administrationType, price),
      quantity: quantity
    }
    if (!items.find(item => item.id === id)) {
      items.push(object)
    }
  })
  return {
    items,
    searchResults: structuredResults,
    basketItems: basket.items,
    basketHasItems: !!basket.items.length
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesAdditionContainer))
